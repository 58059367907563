export const CELLS = [
    {
      _: 0,
      a: "A",
      b: "R",
      c: "I",
      d: "A",
    },
    {
      _: 1,
      n: "T",
    },
    {
      _: 2,
      i: "F",
      n: "E",
    },
    {
      _: 3,
      j: "U",
      n: "R",
    },
    {
      _: 4,
      k: "O",
      n: "R",
    },
    {
      _: 5,
      l: "C",
      n: "A",
    },
    {
      _: 6,
      m: "O",
    },
    {
      _: 7,
    },
    {
      _: 8,
    },
    {
      _: 9,
      e: "A",
      f: "C",
      g: "Q",
      h: "U",
      i: "A",
    },
    {
      _: 10,
    },
  ];