// Grid.vue
<template>
  <div style="display: flex; flex-direction: column">
    <b-table
      class="text-center"
      responsive
      bordered
      outlined
      :items="cells"
      :fields="fields"
      dark
      striped
      foot-clone
    >
      <template #cell(a)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.a">{{ row.item.a }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(b)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.b">{{ row.item.b }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(c)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.c">{{ row.item.c }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(d)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.d">{{ row.item.d }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(e)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.e">{{ row.item.e }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(f)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.f">{{ row.item.f }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(g)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.g">{{ row.item.g }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(h)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.h">{{ row.item.h }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(i)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.i">{{ row.item.i }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(j)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.j">{{ row.item.j }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(k)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.k">{{ row.item.k }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(l)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.l">{{ row.item.l }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(m)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.m">{{ row.item.m }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>

      <template #cell(n)="row">
        <div>
          <div v-if="hitMarkers.includes(row.field.key + '-' + row.index)">
            <h4 v-if="row.item.n">{{ row.item.n }}</h4>
            <img
              v-else
              src="../assets/leaf.svg"
              alt="leaf"
              width="20px"
              height="20px"
            />
          </div>
          <b-button
            v-else
            variant="danger"
            @click="fire(row.field.key + '-' + row.index)"
          >
            <img
              src="../assets/gift.svg"
              alt="gift"
              width="20px"
              height="20px"
            />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-button
      v-if="!showHitMarkersHistory"
      variant="success"
      @click="showHitMarkersHistory = true"
      >Mostra history</b-button
    >
    <b-button
      v-if="showHitMarkersHistory"
      variant="warning"
      @click="showHitMarkersHistory = false"
      >Nascondi history</b-button
    >
    <h2 v-if="showHitMarkersHistory">{{ hitMarkers }}</h2>
  </div>
</template>

<script>
import { CELLS } from "../utils/constants.ts";
import { makeFields } from "../utils/fns.ts";
export default {
  props: ["player"],
  data() {
    return {
      cells: CELLS,
      fields: makeFields(),
      hitMarkers: [],
      missedMarkers: [],
      showHitMarkersHistory: false,
    };
  },
  methods: {
    fire(target) {
      if (!this.hitMarkers.includes(target)) {
        this.hitMarkers = [...this.hitMarkers, target];
      }

      /*if (target)
        this.hitMarkers = [
          ...this.hitMarkers,
          { key: target.key, _: target._ },
        ];
      else
        this.missedMarkers = [
          ...this.missedMarkers,
          { key: target.key, _: target._ },
        ];
        */

      console.log({
        hitMarkers: this.hitMarkers,
      });
    },
    cellClick(cell) {
      console.log(cell);
    },
  },
};
</script>