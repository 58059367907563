<template>
  <div id="app">
    <Grid :player="true" @cellClick="playerClick" />
  </div>
</template>

<script>
import Grid from "./components/Grid.vue";

export default {
  components: {
    Grid,
  },
  methods: {
    playerClick(cell) {
      console.log(cell);
      // Handle player's click on the opponent's grid
    },
  },
};
</script>